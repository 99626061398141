import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <h2>404 – Pagina non trovata</h2>
    <p>Questa pagina non esiste</p>
    <p>
      Ritorna alla <a href="/">Homepage</a>
    </p>
  </Layout>
);

export default NotFoundPage;
